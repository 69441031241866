
import { Media } from "@evercam/shared/types/media"
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent } from "@evercam/shared/types"
import MediaClip from "@evercam/shared/components/Medias/MediaClip"

export default Vue.extend({
  name: "MediaHubGallery",
  components: {
    MediaClip,
  },
  props: {
    mediaItems: {
      type: Array as PropType<Array<Media>>,
      default: () => [],
    },
    totalMediaItems: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapStores(useCameraStore),
    page: {
      get() {
        return this.currentPage
      },
      set(value) {
        this.$emit("page-change", value)
      },
    },
    totalPages(): number {
      return Math.ceil(this.totalMediaItems / 12)
    },
    computedMediaItems(): Array<Media> {
      return this.mediaItems.map((media: Media) => {
        return {
          ...media,
          thumbnailUrl: media.url,
        }
      })
    },
  },

  methods: {
    goToNextPage() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubGoToNextPage)
    },
    goToPreviousPage() {
      this.$analytics.saveEvent(AnalyticsEvent.mediaHubGoToPreviousPage)
    },
  },
})
